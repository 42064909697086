<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Data Berita </strong><small>Tambah Data Baru</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="inputBerita">
            <CRow>
              <CCol sm="12">
                <CInput
                  v-model="judul_berita"
                  label="Judul Berita"
                  placeholder="Input Judul Berita"
                />
              </CCol>
              <CCol sm="12">
                <CTextarea
                  v-model="isi_berita"
                  label="Isi Berita"
                  placeholder="Input Isi Berita"
                />  
              </CCol>
              <!-- <CCol sm="6">             
                <div class="form-group">
                <label>Kategori</label>
                  <select class="form-control" v-model="id_kategori">
                    <option value="" disabled selected>-- Pilih Kategori --</option>
                    <option v-for="r in kategori" :value="r.id_kategori">{{r.nama_kategori}} </option>
                  </select>
                </div>
              </CCol> -->
              <CCol sm="6">
                <div class="form-group">
                  <label>Foto Berita</label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange($event)" id="inputGroupFile01"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
                  </div>
                </div>  
              </CCol>
              <CCol sm="12">
                <CTextarea
                  v-model="caption_gambar_berita"
                  label="Caption Gambar Berita"
                  placeholder="Input Caption Gambar Berita"
                />  
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="penulis"
                  label="Penulis"
                  placeholder="Input Penulis"
                />
              </CCol>
            </CRow>          
              <div class="form-actions">
                <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                <CButton color="secondary" to="/berita">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      :show.sync="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {Datepicker },
  name: 'Forms',
  data () {
    return {
      judul_berita : "",
      isi_berita : "",
      caption_gambar_berita : "",
      penulis : "",
      gambar_berita_text : "",
      gambar_berita : "",
      placeholder : "Pilih File",
      selected : [], // Must be an array reference!
      // kategori : [],
      // subkategori : [],
      show: true,
      modalError: false,
    }
  },
  mounted(){
  // this.loadData();
  },
  
  methods: {

    // loadData: function(event){
      
    //   let username =window.btoa(this.$route.params.id_user);
    //   console.log(username);

    //   axios.get(process.env.VUE_APP_BASE_URL+"api/public/kategoris/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu")
    //     .then((response) => {
    //           this.kategori=response.data;
    //     });
    // },

    onFileChange(event) {
      var fileData = event.target.files[0];
      this.gambar_berita_text = fileData.name;
      this.gambar_berita = fileData;
      this.placeholder = fileData.name;
    },
    
    inputBerita: function(event){
      
      const formData = new FormData();

        if(this.gambar_berita_text){
          formData.append('gambar_berita', this.gambar_berita, this.gambar_berita.name) 
          formData.append('gambar_berita_text', this.gambar_berita.name)
        }        
        
        formData.append('judul_berita', this.judul_berita)
        formData.append('isi_berita', this.isi_berita)
        formData.append('caption_gambar_berita', this.caption_gambar_berita)
        formData.append('penulis', this.penulis)

      axios.post(process.env.VUE_APP_BASE_URL+"berita", formData)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/berita');
            }
            else{
              this.modalError = true;
            }            
        })
    }
  }
}
</script>